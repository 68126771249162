//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Gray colors
$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #ffffff;

$gray-100: #f9f9f9 !default;
$gray-200: #f1f1f2 !default;
$gray-300: #dbdfe9 !default;
$gray-400: #b5b5c3 !default;
$gray-500: #99a1b7 !default;
$gray-600: #78829d !default;
$gray-700: #4b5675 !default;
$gray-800: #252f4a !default;
$gray-900: #071437 !default;

// Theme colors
// Primary
// $primary: #79cddf !default;
$primary: #68D2DF !default;
$primary-active: #4EBBC5 !default;
$primary-light: #EBF8F9 !default;
$primary-light-dark: #477C87 !default;
$primary-inverse: $white !default;

// Secondary colors
$secondary: $gray-300 !default;
$secondary-active: $gray-400 !default;
$secondary-light: $gray-100 !default;
$secondary-light-dark: $gray-800 !default;

$secondary-inverse: $gray-700 !default;

// Success

$success: #16a34a !default;
$success-active: #128837 !default;
$success-light: #ccf2d9 !default;
$success-light-dark: #1c3238;
$success-inverse: $white !default;

// Info
$info: #1d4ed8 !default;
$info-active: #1638b8 !default;
$info-light: #d6e4ff !default;
$info-light-dark: #0f2558 !default;
$info-inverse: $white !default;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

// Card Box Shadow
$card-border-enabled: true;
$card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
$card-box-shadow-dark: none;

// Body
$body-bg-dark: #1c1c1c;
