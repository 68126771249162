// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

#root {
  display: contents;
}

.ProseMirror:focus {
  outline: none;
}
/* set */
.ProseMirror {        
  min-height: 100px;
  overflow: scroll;
  border: 2px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 1rem;
}
